import { Variables } from '../../data/Variables';
import { useEffect, useState } from 'react';
import axios from 'axios';
import '../../App.css';
import Navbar from './../Components/navbar';
import Footer from '../Components/Footer';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function AboutUs() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    const headers = {
        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
    };
    const [data, setData] = useState({});

    return (
        <div className="">
            <div className='fixed top-0 left-0 right-0 z-50'>
                <Navbar />
            </div>
            <Navbar />
            <div className="aboutimg p-12">
                <h1 className='py-28 text-white text-6xl font-bold text-center'>Who Are We?</h1>
            </div>
            {
                isMobile ?
                    <div className='flex flex-col'>
                        <div className='flex flex-col items-center p-10 w-fit bg-gray-100'>
                            <img src='/images/aboutUs/naman.png' className='rounded-full w-72 h-72' />
                            <div className='flex flex-col items-center'>
                                <h1 className='text-4xl font-semibold text-gray-800 mt-5 text-center'>Naman Dhingra</h1>
                                <h1 className='text-xl font-semibold text-gray-400'>Founder</h1>
                                <p className=' text-gray-600 mt-5 text-base'>As the owner and the founder of this consultancy, I am committed to helping businesses of all sizes navigate the challenges of today's dynamic marketplace. With years of experience and a deep understanding of the intricacies of modern business, I am well-equipped to guide my clients to success. My approach to consultancy is founded on a belief in the power of collaboration, working closely with my clients to develop tailored solutions that meet their unique needs. I take pride in staying ahead of the curve when it comes to industry trends and emerging technologies, so that I can provide my clients with the best possible guidance and advice.</p>
                            </div>

                        </div>
                        <div className='flex flex-col items-center p-10 w-fit bg-white'>
                            <img src='/images/aboutUs/harman.png' className='rounded-full w-72 h-72' />
                            <div className='flex flex-col items-center'>
                                <h1 className='text-4xl font-semibold text-gray-800 mt-5 text-center'>Harman Singh Sandhu</h1>
                                <h1 className='text-xl font-semibold text-gray-400'>Managing Director</h1>
                                <p className=' text-gray-600 mt-5 text-base'>I Harman Singh Sandhu as a managing director have a simple mission: to help businesses succeed by providing them with the guidance and support they need to achieve their goals. What sets me apart as a consultant is my ability to listen to my clients' needs, develop customized solutions, and work tirelessly to bring those solutions to life. Through years of experience, I have built a deep understanding of the many challenges that businesses face in today's competitive landscape, and I am here to help you overcome those challenges and reach new heights.</p>
                            </div>

                        </div>
                    </div>
                    :
                    <div className='flex flex-col'>
                        <div className='flex flex-row mx-20 my-10 p-10 w-fit bg-gray-50'>
                            <img src='/images/aboutUs/naman.png' className='ml-24 rounded-full w-80 h-80' />
                            <div className='flex flex-col items-center'>
                                <div className='flex flex-col'>
                                <h1 className='text-4xl font-semibold text-gray-800 mt-5'>Naman Dhingra</h1>
                                <h1 className='text-xl font-semibold text-gray-400'>Founder</h1>
                                </div>
                                <p className=' text-gray-600 mt-5 text-base w-2/4'>As the owner and the founder of this consultancy, I am committed to helping businesses of all sizes navigate the challenges of today's dynamic marketplace. With years of experience and a deep understanding of the intricacies of modern business, I am well-equipped to guide my clients to success. My approach to consultancy is founded on a belief in the power of collaboration, working closely with my clients to develop tailored solutions that meet their unique needs. I take pride in staying ahead of the curve when it comes to industry trends and emerging technologies, so that I can provide my clients with the best possible guidance and advice.</p>
                            </div>

                        </div>
                        <div className='flex flex-row p-10 px-44 mx-20 bg-gray-50 mb-10'>
                            <div className='flex flex-col'>
                                <h1 className='text-4xl font-semibold text-gray-800 mt-5'>Harman Singh Sandhu</h1>
                                <h1 className='text-xl font-semibold text-gray-400'>Managing Director</h1>
                                <p className=' text-gray-600 mt-5 text-base w-2/4'>I Harman Singh Sandhu as a managing director have a simple mission: to help businesses succeed by providing them with the guidance and support they need to achieve their goals. What sets me apart as a consultant is my ability to listen to my clients' needs, develop customized solutions, and work tirelessly to bring those solutions to life. Through years of experience, I have built a deep understanding of the many challenges that businesses face in today's competitive landscape, and I am here to help you overcome those challenges and reach new heights.</p>
                            </div>
                            <img src='/images/aboutUs/harman.png' className='mr-24 rounded-full w-80 h-80' />

                        </div>
                    </div>
            }
            <Footer />
        </div>
    )
}