import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

function FuturePortal() {
    const [isMobile, setIsMobile] = useState(false);
    const [navActive, setNavActive] = useState(false);
    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);
    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);

    const data = [
        {
            Image: "/images/portals/1.webp", heading: "GIC Search", text: "Explore and shortlist courses easily based on your preferences.",
            button: "Explore",
            url: "/study"
        },
        {
            Image: "/images/portals/2.webp", heading: "GIC Student", text: "Apply and track your university applications hassle-free in one place.",
            button: "Click Here",
            url: "/study"
        },
        {
            Image: "/images/portals/3.webp", heading: "GIC Skills", text: "Find out what personality test can tell about your ideal career path",
            button: "Student Visa",
            url: "/study"
        },{
            Image: "/images/portals/4.webp", heading: "Abroad View", text: "Watch exclusive, informational videos about top global universities",
            button: "Study Abroad",
            url: "/study"
        }
    ];
    return (
        <div className='bg-violet-400'>
            <h1 data-aos="fade-right" className=' font-custom text-4xl font-bold text-gray-800 pt-10 mt-16 mb-14 mx-auto ml-5 mr-5'>With you at every step - from one point to another</h1>
            <div className='flex flex-wrap justify-center'>
                {data.map((item, index) => {
                    return (
                        <div data-aos="fade-right"
                        key={index} 
                        className={(isMobile ? 'w-fit mx-10 ' : 'w-72 mx-3 ') + ' rounded-3xl bg-green-300 flex flex-col  justify-center mb-10 h-max'}>
                            <img className='mt-5 mx-auto w-44' src={item.Image} alt="Logo" />
                            <div className='flex flex-col justify-start text-start px-10 bg-green-300 rounded-xl'>
                                <h1 className='font-custom text-2xl text-gray-800 mt-4 mb-4 text-center'>{item.heading}</h1>
                                <p className='font-custom text-sm h-14 text-gray-800 mb-5 break-words'>{item.text}</p>
                                <Link
                                    className=" bg-black hover:bg-violet-700 hover:text-white text-white font-bold py-3 rounded-full w-fit  mx-auto px-6 mb-10"
                                    to={item.url}>
                                    {item.button}
                                </Link>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default FuturePortal;
