import { Variables } from '../../data/Variables';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Users() {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState({
        userName: '',
        email: '',
        role: '',
        password: '',
    });

    const headers = {
        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
    };
    const [data, setData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            // setLoading(true);
            try {
                const response = await axios.get(Variables.PRODUCTION_URL + 'api/users/getusers', { headers });
                setData(response.data);
            } catch (err) {
                console.log(err);
            } finally {
            }
        };
        fetchData();
    }, []);

    const deleteAdmin = async (id) => {
        try {
            const response = await axios.put(Variables.PRODUCTION_URL + 'api/auth/removeAdmins?id=' + id, { headers });
            console.log(response.data);

        } catch (err) {
            console.log(err.message);
        } finally {
        }
    }

    const handleChange = event => {
        setFormData({ ...formData, [event.target.name]: event.target.value });

    };

    const handleSubmit = event => {
        event.preventDefault();
        console.log(formData);
        axios.post(Variables.PRODUCTION_URL + 'api/auth/addadmin', formData, { headers })
            .then((response) => {
                setSuccess(true);
                setError(false);
                window.location.reload();
            })
            .catch((error) => {
                setError(true);
                setSuccess(false);
                console.error(error.response.data);
            })
            .finally(() => {
                setFormData({
                    userName: '',
                    email: '',
                    role: '',
                    password: '',
                });
            });
    };

    return (
        <div>
            <form
                onSubmit={handleSubmit}
                className="mx-auto mt-2 bg-white p-6 justify-around items-center rounded-lg shadow-md flex flex-col"
            >
                <h2 className="text-lg mx-auto font-medium mb-4 underline">Add Admins</h2>
                <div className='flex flex-row justify-around'>
                    <div className="mb-4 mr-5">
                        <label className="block font-medium mb-2 text-center">Username</label>
                        <input
                            type="text"
                            name='userName'
                            className="border p-2 rounded-lg w-full"
                            value={formData.userName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-2 mr-5">
                        <label className="block font-medium mb-2 text-center">Email</label>
                        <input
                            type="email"
                            name='email'
                            className="border p-2 rounded-lg w-full"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-2 mr-5">
                        <label className="block font-medium mb-2 text-center">Role</label>
                        <input
                            type="text"
                            name='role'
                            className="border p-2 rounded-lg w-full"
                            value={formData.role}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-2 mr-5">
                        <label className="block font-medium mb-2 text-center">Password</label>
                        <input
                            type="password"
                            name='password'
                            className="border p-2 rounded-lg w-full"
                            value={formData.password}
                            onChange={handleChange}
                        />
                    </div>
                    <button type="submit" className="mx-auto my-8 w-60 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600">
                        Login
                    </button>
                </div>
                {success && <p className='p-2 px-4 bg-green-50 text-green-500 rounded-full'>Admin is added to our database</p>}
                {error && <p className='p-2 px-4 bg-red-50 text-red-500 rounded-full'>An error occured</p>}
            </form>
            <div className="overflow-hidden w-fit mx-auto mt-16 rounded-lg border border-gray-200 shadow-md m-5">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">Admins</h3>
                </div>
                <table className="w-fit border-collapse bg-white text-left text-sm text-gray-500">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">Username</th>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">Email</th>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">role</th>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">Status</th>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                        {Object.values(data).map((user, index) => (
                            <tr className="hover:bg-gray-50" key={index}>
                                <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                                    <div className="font-medium text-gray-700">{user.userName}</div>
                                </th>
                                <td className="px-6 py-4">
                                    {user.email}
                                </td>
                                <td className="px-6 py-4">
                                    {user.role}
                                </td>
                                {user.userStatus === "Active" ?
                                    <td className="px-6 py-4">
                                        <span
                                            className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 mr-5"
                                        >
                                            {user.userStatus}
                                        </span>
                                    </td>
                                    :
                                    <td className="px-6 py-4">
                                        <span
                                            className="inline-flex items-center gap-1 rounded-full bg-red-50 px-2 py-1 text-xs font-semibold text-red-600 mr-5"
                                        >
                                            {user.userStatus}
                                        </span>
                                    </td>
                                }
                                <td className="px-6 py-4">
                                    <div className="flex gap-3">
                                        {user.userStatus === "Active" ?

                                            <button
                                                className="inline-flex items-center gap-1 rounded-full bg-red-50 px-2 py-1 text-xs font-semibold text-red-600 mr-5"
                                                onClick={() => deleteAdmin(user.id)}
                                            >
                                                Delete
                                            </button>
                                            :
                                            <button

                                                className="inline-flex items-center gap-1 rounded-full bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-600 mr-5"
                                                onClick={() => console.log(null)}
                                            >
                                                Delete
                                            </button>
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}