import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { Variables } from './../../data/Variables';

const LoginPage = () => {

    useEffect(() => {
        const token = localStorage.getItem('jwtToken');
        if (token) {
            navigate('/admin');
        }
    }, []);


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(Variables.PRODUCTION_URL + 'api/auth/login', { email, password })
            .then((response) => {
                setError('');
                localStorage.setItem('jwtToken', response.data);
                console.log(response.data);
                navigate('/admin');
            })
            .catch((error) => {
                setError(error.response.data);
                console.error(error.message);
            });

    };

    return (
        <div className="bg-gray-100 h-screen flex items-center justify-center">
            <form
                onSubmit={handleSubmit}
                className="bg-white p-6 rounded-lg shadow-md w-96 flex flex-col"
            >
                <h2 className="text-lg mx-auto font-medium mb-4">Login</h2>
                <div className="mb-4">
                    <label className="block font-medium mb-2">Email</label>
                    <input
                        type="email"
                        className="border p-2 rounded-lg w-full"
                        // value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <div className="mb-2">
                    <label className="block font-medium mb-2">Password</label>
                    <input
                        type="password"
                        className="border p-2 rounded-lg w-full"
                        // value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>
                <Link to=''>
                    <pre className="text-xs mb-4 text-right">forget password?</pre>
                </Link>
                <p className="text-red-500 text-sm text-center mb-2">{error}</p>
                <button type="submit" className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600">
                    Login
                </button>
            </form>
        </div>
    );
};

export default LoginPage;
