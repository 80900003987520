import { Variables } from '../../data/Variables';
import { useEffect, useState } from 'react';
import axios from 'axios';
import '../../App.css';
import Navbar from './../Components/navbar';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';

const navigation = [
    { name: 'Contacts', href: '#', current: true },
    { name: 'Partners', href: '#', current: false },
    { name: 'Carrers', href: '#', current: false },
    { name: 'Home Page', href: '#', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function StudyAbroad() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    const headers = {
        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
    };
    const [data, setData] = useState({});

    return (
        <div className="">
            <div className='fixed top-0 left-0 right-0 z-50'>
                <Navbar />
            </div>
            <Navbar />
            {isMobile ?
                <div className="bg-gradient-to-r flex flex-row from-cyan-600 to-purple-600 p-10">
                    <div  data-aos="fade-right" className='flex flex-col justify-center items-center mx-auto'>
                        <h1 className=' text-white text-3xl mb-10 text-center'>Experience the diverse culture & incredible landscape of Australia</h1>
                        <p className='text-white text-center font-medium'>Australia is a modern, democratic country with great healthcare, excellent educational institutions and a geography that’s like no place else on earth.</p>
                    </div>
                </div>
                :
                <div className="bg-gradient-to-r flex flex-row from-cyan-600 to-purple-600">
                    <img  data-aos="fade-right" src='/images/background/abroad.png' className='h-80' />
                    <div  data-aos="fade-right" className='flex flex-col justify-center items-center mx-auto'>
                        <h1 className=' text-white text-3xl mb-10'>Experience the diverse culture & incredible landscape of Australia</h1>
                        <p className='text-white text-center font-medium'>Australia is a modern, democratic country with great healthcare,<br />
                            excellent educational institutions and a geography that’s like no place else on earth.</p>
                    </div>
                </div>
            }
            <div  data-aos="fade-right" className=" flex flex-col p-10 justify-center items-center">
                <h1 className=' text-black text-3xl mb-10'>Why Study in Abroad?</h1>
                <p className={isMobile ? '' : ' w-3/4 ' + 'text-black text-center font-medium '}>The world is full of wonder and diversity, and each day presents an opportunity to learn something new. Whether it's exploring a new culture, discovering a new skill, or simply enjoying the beauty of nature, life is a journey of discovery. By embracing curiosity and an open mind, we can enrich our lives and connect with the world around us. Every experience, no matter how big or small, can teach us something valuable and help us grow as individuals. So let's savor each moment and embrace the richness of life.</p>
                <Link to='/contact' className='mt-10 bg-gradient-to-r text-white p-5 py-4 rounded-xs from-orange-500 to-pink-500'>Contact Us To Study In Abroad</Link>
            </div>
            {
                isMobile ?
                    <div className='flex flex-col justify-center items-center bg-gray-200'>

                        <div  data-aos="fade-left" className='flex flex-col p-10 bg-gray-100 pt-20'>
                            <img src='/images/study/australia.jpg' className='w-full rounded-full' />
                            <div>
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Study in Australia</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>Australian universities are rated amongst the best in the world, with 8 of them ranked in the Top 100
                                    The Australian University system is even better than Germany and New Zealand (9th in the world)
                                    Most universities are located in capital cities that rank in the top 30 most liveable cities for students
                                    Over $200,000,000 is spent annually on international scholarships by the Government
                                    A huge number of international students choose Australia each year as their study destination, owing to its welcoming environment.
                                    Rest assured, with a great education system in place, you can live, study and experience a culture with plenty of tourist attractions and well-equipped transportation facilities.
                                </p>
                            </div>
                        </div>
                        <div  data-aos="fade-right" className='flex flex-col p-10'>
                            <div>
                                <img src='/images/study/canada.jpg' className='w-full rounded-full' />
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Study in Canada</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>It’s a well-known fact that studying in Canada is much more affordable when compared to other top study destinations.

                                    Usually, undergraduate fees for international students are around CA$25,000 per year*, depending on what course of study and the university.

                                    Studying more expensive courses such as engineering and medicine can cost on average CA$28,000 per year as an undergraduate. Moreover, there are aplenty scholarships in Canada to apply for if you want to minimize your expenditure efficiently
                                </p>
                            </div>
                        </div>
                        <div  data-aos="fade-left" className='flex flex-col p-10 bg-gray-100'>
                            <img src='/images/study/usa.jpg' className='w-full rounded-full' />
                            <div>
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Study in USA</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>Studying in the USA can be expensive. With state colleges charging upwards of USD,000 or more per year, and private colleges averaging USD,000 or more for an undergraduate bachelor’s degree, managing your costs can be challenging.

                                    If you’re after an Associate Degree, you can gain one from a public college, starting at a much more affordable USD$ 3,300 per annum.
                                </p>
                            </div>
                        </div>
                        <div  data-aos="fade-right" className='flex flex-col p-10'>
                            <div>
                                <img src='/images/study/uk.jpg' className='w-full rounded-full' />
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Study in UK</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>Students from different countries around the globe choose to study in the UK due to its affordability and high standards of education. It takes less time to attain degrees in the UK than in other prospective study destinations.

                                    While other countries take at least four years for an undergraduate degree and two or three years for a postgraduate degree, it takes only three years for an undergraduate degree, and one year for a postgraduate in the United Kingdom. This overall cuts down your expenditure on your education, without compromising on the knowledge sharing and quality of teaching.
                                </p>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='flex flex-col justify-center items-center bg-gray-200'>

                        <div  data-aos="fade-left" className='flex flex-row w-2/3 p-10 bg-gray-100 mt-20'>
                            <img src='/images/study/australia.jpg' className='h-96 mr-10' />
                            <div>
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Study in Australia</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>
                                    Australian universities are rated amongst the best in the world, with 8 of them ranked in the Top 100
                                    The Australian University system is even better than Germany and New Zealand (9th in the world)
                                    Most universities are located in capital cities that rank in the top 30 most liveable cities for students
                                    Over $200,000,000 is spent annually on international scholarships by the Government
                                    A huge number of international students choose Australia each year as their study destination, owing to its welcoming environment.
                                    Rest assured, with a great education system in place, you can live, study and experience a culture with plenty of tourist attractions and well-equipped transportation facilities.
                                </p>
                            </div>
                        </div>
                        <div  data-aos="fade-right" className='flex flex-row w-2/3 p-10'>
                            <div>
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Study in Canada</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>It’s a well-known fact that studying in Canada is much more affordable when compared to other top study destinations.

                                    Usually, undergraduate fees for international students are around CA$25,000 per year*, depending on what course of study and the university.

                                    Studying more expensive courses such as engineering and medicine can cost on average CA$28,000 per year as an undergraduate. Moreover, there are aplenty scholarships in Canada to apply for if you want to minimize your expenditure efficiently
                                </p>
                            </div>
                            <img src='/images/study/canada.jpg' className='h-96 ml-10' />
                        </div>
                        <div  data-aos="fade-left" className='flex flex-row w-2/3 p-10 bg-gray-100'>
                            <img src='/images/study/usa.jpg' className='h-96 mr-10' />
                            <div>
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Study in USA</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>Studying in the USA can be expensive. With state colleges charging upwards of USD,000 or more per year, and private colleges averaging USD,000 or more for an undergraduate bachelor’s degree, managing your costs can be challenging.

                                    If you’re after an Associate Degree, you can gain one from a public college, starting at a much more affordable USD$ 3,300 per annum.
                                </p>
                            </div>
                        </div>
                        <div  data-aos="fade-right" className='flex flex-row w-2/3 p-10'>
                            <div>
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Study in UK</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>Students from different countries around the globe choose to study in the UK due to its affordability and high standards of education. It takes less time to attain degrees in the UK than in other prospective study destinations.

                                    While other countries take at least four years for an undergraduate degree and two or three years for a postgraduate degree, it takes only three years for an undergraduate degree, and one year for a postgraduate in the United Kingdom. This overall cuts down your expenditure on your education, without compromising on the knowledge sharing and quality of teaching.
                                </p>
                            </div>
                            <img src='/images/study/uk.jpg' className='h-96 ml-10' />
                        </div>
                    </div>
            }
            <Footer />
        </div>
    )
}