import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { ContactData } from '../../data/contactus';
import SelectMenu from './CountryCodes';
import '../../App.css';
import { Variables } from '../../data/Variables';
import AOS from 'aos';
import 'aos/dist/aos.css';
function ContactUs() {

    const [isMobile, setIsMobile] = useState(false);
    // const [dialCodes, setDialCodes] = useState({});
    // const [loading, setLoading] = useState(false);
    // const [error, setError] = useState(null);

    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
        // const fetchData = async () => {
        //     setLoading(true);
        //     try {
        //         const response = await axios.get('http://localhost:8080/api/Users/GetAllCountriesWithTheirCountryCodes');
        //         setDialCodes(response.data);
        //     } catch (err) {
        //         setError(err);
        //     } finally {
        //         setLoading(false);
        //     }
        // };
        // fetchData();
    }, []);
    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: '',
        questionRegarding: '',
        countryOfPassport: '',
        currentLocation: '',
        educationFunding: '',
        preferredStudyDestination: ''
    });
    const [notification, setNotification] = useState(false);
    const [code, setCode] = useState(SelectMenu.value);
    const [submitted, setSubmitted] = useState(false);
    const [errorSending, setErrorSending] = useState(false);
    const [mailSuccess, setmailSuccess] = useState(false);
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: '',
        question: '',
        countryOfPassport: '',
        currentLocation: '',
        educationFunding: '',
        preferredStudyDestination: '',
    });

    const handleChange = event => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
        setErrors({ ...errors, [event.target.name]: '' });

    };

    const handleSubmit = event => {
        event.preventDefault();
        const newErrors = {};

        Object.keys(formData).forEach(field => {
            if (!formData[field]) {
                newErrors[field] = 'This field is required.';
            }
        });
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            const combinedData = {
                ...formData,
                mobileNumber: code + formData.mobileNumber,
                receiveNotification: notification,
                _captcha: false
            };

            const formDatas = new FormData();
            for (const key in combinedData) {
                formDatas.append(key, combinedData[key]);
            }

            axios.post('https://formsubmit.co/namandhingrarj13@gmail.com', formDatas)
                .then((response) => {
                    if (response.status === 200) {
                        setErrorSending(false);
                        setmailSuccess(true);
                        console.log(response);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setmailSuccess(false);
                    setErrorSending(true);
                })
                .finally(() => {
                    setFormData({
                        firstName: '',
                        lastName: '',
                        email: '',
                        mobileNumber: '',
                        questionRegarding: '',
                        countryOfPassport: '',
                        currentLocation: '',
                        educationFunding: '',
                        preferredStudyDestination: ''
                    });
                    setNotification(false);
                    setCode(SelectMenu.value);
                });
        }
    };
    const selectcode = (value) => {
        setCode(value);
    }

    return (
        <div data-aos="fade-up">
            <form className="bg-cyan-300 w-full p-10 pb-5 pr-5 rounded-lg" onSubmit={handleSubmit}>
                {errorSending &&
                    <label className="block text-red-500 text-center font-bold mx-auto mb-5 pr-4">
                        An error occured while submitting the form. Please try again.
                    </label>
                }
                {mailSuccess &&
                    <label className="block text-green-500 text-center font-bold mx-auto mb-5 pr-4">
                        Form is submitted successfully
                    </label>
                }
                <div className="md:flex md:items-center justify-between mb-6">
                    <label className="block text-black text-sm font-bold text-start md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                        First Name
                    </label>
                    <div className="md:w-2/3">
                        <input className="bg-white appearance-none border-2 border-gray-200 rounded-2xl w-full py-1 px-4 text-gray-600 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                        {errors.firstName && <p className="text-red-500 text-start text-xs">{errors.firstName}</p>}
                    </div>
                </div>
                <div className="md:flex md:items-center mb-6 justify-between">
                    <label className="block text-black text-sm font-bold text-start md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                        Last Name
                    </label>
                    <div className="md:w-2/3">
                        <input className="bg-white appearance-none border-2 border-gray-200 rounded-2xl w-full py-1 px-4 text-gray-600 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                        {errors.lastName && <p className="text-red-500 text-start text-xs">{errors.lastName}</p>}
                    </div>
                </div>
                <div className="md:flex md:items-center mb-6 justify-between">
                    <label className="block text-black text-sm font-bold text-start md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                        Email
                    </label>
                    <div className="md:w-2/3">
                        <input className="bg-white appearance-none border-2 border-gray-200 rounded-2xl w-full py-1 px-4 text-gray-600 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {errors.email && <p className="text-red-500 text-start text-xs">{errors.email}</p>}
                    </div>
                </div>
                <div className="md:flex md:items-center mb-6 justify-between">
                    <label className="block text-black text-sm font-bold text-start md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                        Mobile Number
                    </label>
                    <div className="md:w-2/3">
                        <SelectMenu selectcode={selectcode} />
                        <input className="bg-white appearance-none border-2 border-gray-200 rounded-2xl w-full py-1 pr-4 padding text-gray-600 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            type="number"
                            id="mobileNumber"
                            name="mobileNumber"
                            value={formData.mobileNumber}
                            onChange={handleChange}
                        />
                        {errors.mobileNumber && <p className="text-red-500 text-start text-xs">{errors.mobileNumber}</p>}
                    </div>
                </div>
                <div className="md:flex md:items-center mb-6 justify-between">
                    <label className="block text-black text-sm font-bold text-start md:text-right mb-1 md:mb-0 pr-4 whitespace-nowrap" htmlFor="inline-password">
                        My question is regarding
                    </label>
                    <div className="md:w-2/3">
                        <select className="block appearance-none w-full bg-white border border-gray-200 text-gray-600 py-1 px-4 pr-8 rounded-2xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="questionRegarding"
                            name="questionRegarding"
                            onChange={handleChange}
                            value={formData.questionRegarding}
                        >
                            <option value="" disabled hidden>Select one</option>
                            {ContactData.question.map((item, index) => <option key={index}>{item}</option>)}

                        </select>
                        {errors.question && <p className="text-red-500 text-start text-xs">{errors.question}</p>}
                    </div>
                </div>
                <div className="md:flex md:items-center mb-6 justify-between whitespace-nowrap">
                    <label className="block text-black text-sm font-bold text-start md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-password">
                        Country of Passport
                    </label>
                    <div className="md:w-2/3">
                        <select className="block appearance-none w-full bg-white border border-gray-200 text-gray-600 py-1 px-4 pr-8 rounded-2xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="countryOfPassport"
                            name="countryOfPassport"
                            onChange={handleChange}
                            value={formData.countryOfPassport}
                        >
                            <option value="" disabled hidden>Select one</option>
                            {ContactData.countries.map((item, index) => <option key={index}>{item}</option>)}
                        </select>
                        {errors.countryOfPassport && <p className="text-red-500 text-start text-xs">{errors.countryOfPassport}</p>}
                    </div>
                </div>
                <div className="md:flex md:items-center mb-6 justify-between">
                    <label className="block text-black text-sm font-bold text-start md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-password">
                        Current Location
                    </label>
                    <div className="md:w-2/3">
                        <select className="block appearance-none w-full bg-white border border-gray-200 text-gray-600 py-1 px-4 pr-8 rounded-2xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="currentLocation"
                            name="currentLocation"
                            onChange={handleChange}
                            value={formData.currentLocation}
                        >
                            <option value="" disabled hidden>Select one</option>
                            {ContactData.countries.map((item, index) => <option key={index}>{item}</option>)}

                        </select>
                        {errors.currentLocation && <p className="text-red-500 text-start text-xs">{errors.currentLocation}</p>}
                    </div>
                </div>
                <div className="md:flex md:items-center mb-6 justify-between">
                    <label className="block text-black text-sm font-bold text-start md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-password">
                        Education Funding
                    </label>
                    <div className="md:w-2/3">
                        <select className="block appearance-none w-full bg-white border border-gray-200 text-gray-600 py-1 px-4 pr-8 rounded-2xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="educationFunding"
                            name="educationFunding"
                            onChange={handleChange}
                            value={formData.educationFunding}
                        >
                            <option value="" disabled hidden>Select one</option>
                            {ContactData.education.map((item, index) => <option key={index}>{item}</option>)}
                        </select>
                        {errors.educationFunding && <p className="text-red-500 text-start text-xs">{errors.educationFunding}</p>}
                    </div>
                </div>
                <div className="md:flex md:items-center mb-10 justify-between">
                    <label className="block  text-black text-sm font-bold text-start mb-1 md:mb-0 " htmlFor="inline-password">
                        Preferred study destination
                    </label>
                    <div className="md:w-2/3">
                        <select className="block appearance-none w-full bg-white border border-gray-200 text-gray-600 py-1 px-4 pr-8 rounded-2xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="preferredStudyDestination"
                            name="preferredStudyDestination"
                            onChange={handleChange}
                            value={formData.preferredStudyDestination}
                        >
                            <option value="" disabled hidden>Select one</option>
                            {ContactData.destination.map((item, index) => <option key={index}>{item}</option>)}
                        </select>
                        {errors.preferredStudyDestination && <p className="text-red-500 text-start text-xs">{errors.preferredStudyDestination}</p>}
                    </div>
                </div>
                <div className="flex mb-10 justify-center">
                    <label className="flex fle-row text-black text-sm font-bold text-center">
                        <input className="mr-2 leading-tight w-3 mb-0" type="checkbox"
                            id="notification"
                            name="notification"
                            onChange={() => setNotification(!notification)}
                        />
                        <span className="text-sm">
                            Send me your newsletter!
                        </span>
                    </label>
                </div>
                <div className="md:flex md:items-center">
                    <button type='submit' className="block shadow bg-black hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-1 px-4 rounded mx-auto">
                        SUBMIT
                    </button>
                </div>
            </form>
        </div>
    )
}
export default ContactUs;