import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';

function Navbar() {
    const [isMobile, setIsMobile] = useState(false);
    const [navActive, setNavActive] = useState(false);
    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);
    const toggleMenu = () => {
        setNavActive(!navActive);
    };
    return (
        <div className="">

            {isMobile ?
                <div>
                    <div className="pl-5 py-6 flex flex-row items-center justify-around bg-white">
                        <img className='h-12' src="/images/gic123.png" alt="Logo" />
                        <Link to='/contact' className=" font-custom bg-blue-800 hover:bg-blue-700 hover:text-white text-white font-bold py-2 px-6 rounded-full w-fit">
                            Apply
                        </Link>
                    </div>
                    {/* <div className="py-3 flex flex-row justify-between bg-white">
                        <div className='flex flex-row items-center justify-center'>
                            <img className='h-6 ml-6' src="/images/icons/facebook.svg" alt="Logo" />
                            <img className='h-6 ml-6' src="/images/icons/instagram1.svg" alt="Logo" />
                            <img className='h-6 ml-6' src="/images/icons/location.svg" alt="Logo" />
                            <img className='h-6 ml-6' src="/images/icons/twitter.svg" alt="Logo" />
                        </div>
                        <img className='h-12 mr-10' src="/images/gic.png" alt="Logo" />
                    </div> */}
                    <div className=''>
                        <nav className="bg-white text-black">
                            <div className=' h-px mx-auto border-gray-400 bordercustom' />
                            <div className="container mx-auto mb-10 ml-5">
                                <div className="flex items-center">
                                    <div>
                                        <img
                                            className='h-7 mt-3 absolute'
                                            src={navActive ? "/images/icons/cross.svg" : "/images/icons/bars-solid.svg"}
                                            alt="Logo"
                                            onClick={() => { setNavActive(!navActive); }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {navActive ?
                                <div className='border-b  h-3 mx-auto border-black' />
                                :
                                <div className='h-3 mx-auto' />
                            }
                        </nav>
                    </div>
                    {navActive &&
                        <nav className="bg-white text-black">
                            <div className="container mx-auto mb-2">
                                <div className="flex flex-col items-start">
                                    <Link to="/home" className="px-4 py-2 hover:text-blue-700 font-semibold">HOME</Link>
                                    <Link to="/student" className="px-4 py-2 hover:text-blue-700 font-semibold">STUDENT SERVICES</Link>
                                    <Link to="/study" className="px-4 py-2 hover:text-blue-700 font-semibold">STUDY ABROAD</Link>
                                    <Link to="/about" className="px-4 py-2 hover:text-blue-700 font-semibold">ABOUT US</Link>
                                    <Link to="/contact" className="px-4 py-2 hover:text-blue-700 font-semibold">CONTACT US</Link>
                                </div>
                            </div>
                        </nav>
                    }
                </div>
                :
                <div>
                    <div className="pl-5 py-4 flex flex-row bg-white">

                        <img className='h-16 mx-auto' src="/images/gic123.png" alt="Logo" />
                    </div>
                    <div className='border-t w-4/5 mx-auto border-black'>
                        <div className="flex items-center justify-center w-min absolute">
                            <div className='flex flex-row -top-11 relative'>
                                <Link className='ml-6' to='https://m.facebook.com/thegicsgnr/'>
                                    <img className='h-5 ml-6' src="/images/icons/facebook.svg" alt="Logo" />
                                </Link>
                                <Link className='ml-6' to='https://www.instagram.com/thegicsgnr/'>
                                    <img className='h-5 ml-6' src="/images/icons/instagram1.svg" alt="Logo" />
                                </Link>
                                <Link className='ml-6' to='https://goo.gl/maps/uKeSPXdhApYf1hbe7'>
                                    <img className='h-5 ml-6' src="/images/icons/location.svg" alt="Logo" />
                                </Link>
                                <Link className='ml-6' to='https://www.linkedin.com/in/sahildhingraa/'>
                                    <img className='h-5 ml-6' src="/images/icons/linkedin.svg" alt="Logo" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <nav className="bg-white text-black">
                        <div className="container mx-auto pb-2">
                            <div className="pt-2 flex items-center justify-center">
                                <div>
                                    <Link to="/home" className="px-4 py-2 hover:text-blue-700 font-semibold">HOME</Link>
                                    <Link to="/student" className="px-4 py-2 hover:text-blue-700 font-semibold">STUDENT SERVICES</Link>
                                    <Link to="/study" className="px-4 py-2 hover:text-blue-700 font-semibold">STUDY ABROAD</Link>
                                    <Link to="/about" className="px-4 py-2 hover:text-blue-700 font-semibold">ABOUT US</Link>
                                    <Link to="/contact" className="px-4 py-2 hover:text-blue-700 font-semibold">CONTACT US</Link>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            }
        </div>
    );
}

export default Navbar;
