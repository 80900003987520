import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';

function Strip() {
    const [isMobile, setIsMobile] = useState(false);
    const [navActive, setNavActive] = useState(false);
    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);
    const data = [
        {
            text: "An extensive library of content backed by research and updated with the latest information on diverse study abroad topics.",
            button: "Contact Us",
            url: "/contact",
            color: " bg-fuchsia-300 ",
            tilt: "Read",
            image: "/images/portals/read.png"
        },
        {
            text: "From campus tours to visa updates to student life, watch exclusive videos to discover and learn more about student life at the top study destinations in the world.",
            button: "Contact Us",
            url: "/contact",
            color: " bg-sky-300 ",
            tilt: "Watch",
            image: "/images/portals/watch.png"
        },
        {
            text: "Now, you can listen to our study abroad experts, international students and the world's best universities on the go as they share interesting facts and tips.",
            button: "Contact Us",
            url: "/contact",
            color: " bg-yellow-200 ",
            tilt: "Listen",
            image: "/images/portals/listen.png"
        }
    ];
    return (
        <div className='flex flex-wrap justify-center my-14'>
            {data.map((item, index) => {
                return (
                    <div 
                        key={index}
                        className={(isMobile ? 'w-full ' : 'w-1/3') + ' flex flex-col  justify-center h-max'}>
                        <div className={'' + item.color + ' flex flex-col justify-start text-start  h-max'}>
                            <img data-aos="fade-right" className={isMobile ? 'absolute overflow-hidden mt-10' : ' overflow-hidden absolute w-1/3'} src={item.image} alt='tilt' />
                            <p data-aos="fade-right" className='ml-10 mt-10 font-custom  text-gray-800 mb-5 w-2/3 break-words h-32'>{item.text}</p>
                            <Link data-aos="fade-right"
                                className="ml-10 bg-black hover:bg-violet-700 hover:text-white text-white font-bold py-3 rounded-full w-fit  px-6 mb-10"
                                to={item.url}>
                                {item.button}
                            </Link>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default Strip;
