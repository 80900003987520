import axios from 'axios';
import React, { useState, useEffect } from 'react';
import '../../App.css';
import Footer from '../Components/Footer';
import Navbar from '../Components/navbar';
import ContactUs from './../Components/contactUs';
function Contact() {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    return (
        <div className='bgimg' >
            <div className='fixed top-0 left-0 right-0 z-50'>
                <Navbar />
            </div>
            <Navbar />
            {isMobile ?
                <div className='flex flex-col items-center my-10'>
                    <h1 className='text-4xl text-center font-semibold text-white mx-10'>Speak With Our Experts</h1>
                    <p className='text-white text-center mt-5 text-base mb-6 mx-10'>To get in touch with our expert team, simply leave your details below and we’ll call you for a FREE consultation.</p>
                    <div className='width'>
                    <ContactUs />
                    </div>
                </div>
                :
                <div className='flex flex-row justify-center items-center my-16 m-10'>
                    <div className='mr-28'>
                        <h1 className='text-5xl font-semibold text-white'>Speak With <br />Our Experts</h1>
                        <p className='text-white mt-5 text-base'>To get in touch with our expert team,<br /> simply leave your details below and we’ll<br />call you for a FREE consultation.</p>
                        <img className='ml-14 mt-3 w-60' src='/images/icons/arrow-right.webp' />
                    </div>
                    <ContactUs />
                </div>
            }
            <Footer />
        </div>
    )
}
export default Contact;