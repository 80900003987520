import { Variables } from '../../data/Variables';
import { useEffect, useState } from 'react';
import axios from 'axios';

const navigation = [
    { name: 'Contacts', href: '#', current: true },
    { name: 'Partners', href: '#', current: false },
    { name: 'Carrers', href: '#', current: false },
    { name: 'Home Page', href: '#', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Careers() {

    const headers = {
        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
    };
    const [data, setData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            // setLoading(true);
            try {
                const response = await axios.get(Variables.PRODUCTION_URL + 'api/forms/careers', { headers });
                setData(response.data);
            } catch (err) {
                console.log(err);
            } finally {
            }
        };
        fetchData();
    }, []);

    return (
        <div className="overflow-hidden w-fit mx-auto mt-16 rounded-lg border border-gray-200 shadow-md m-5">
            <table className="w-fit border-collapse bg-white text-left text-sm text-gray-500">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Full Name</th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Email</th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Number</th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Partnership Type</th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Institution website</th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Institute name</th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Location</th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Comments</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                    {Object.values(data).map((user, index) => (
                        <tr className="hover:bg-gray-50" key={index}>
                            <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                                <div className="font-medium text-gray-700">{user.fullName}</div>
                            </th>

                            <td className="px-6 py-4">
                                {user.email}
                            </td>
                            <td className="px-6 py-4">
                                {user.phone}
                            </td>
                            <td className="px-6 py-4">
                                {user.partnershipType}
                            </td>
                            <td className="px-6 py-4">
                                {user.institutionWebsite}
                            </td>
                            <td className="px-6 py-4">
                                {user.instituteName}
                            </td>
                            <td className="px-6 py-4">
                                {user.location}
                            </td>
                            <td className="px-6 py-4">
                                {user.comments}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}