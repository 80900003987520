import { Variables } from '../../data/Variables';
import { useEffect, useState } from 'react';
import axios from 'axios';

const navigation = [
    { name: 'Contacts', href: '#', current: true },
    { name: 'Partners', href: '#', current: false },
    { name: 'Carrers', href: '#', current: false },
    { name: 'Home Page', href: '#', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function AllContacts() {

    const headers = {
        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
    };
    const [data, setData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            // setLoading(true);
            try {
                const response = await axios.get(Variables.PRODUCTION_URL + 'api/forms/getcontacts', { headers });
                setData(response.data);
            } catch (err) {
                console.log(err);
            } finally {
            }
        };
        fetchData();
    }, []);

    return (
        <div className="overflow-hidden w-fit mx-auto mt-16 rounded-lg border border-gray-200 shadow-md m-5">
            <table className="w-fit border-collapse bg-white text-left text-sm text-gray-500">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Full Name</th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Email</th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Number</th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Question regarding</th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Country of passport</th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Current location</th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Education Funcding</th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">Study Destination</th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900"></th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                    {Object.values(data).map((user, index) => (
                        <tr className="hover:bg-gray-50" key={index}>
                            <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                                <div className="font-medium text-gray-700">{user.fullName}</div>
                            </th>

                            <td className="px-6 py-4">
                                {user.email}
                            </td>
                            <td className="px-6 py-4">
                                {user.mobileNumber}
                            </td>
                            <td className="px-6 py-4">
                                {user.questionRegarding}
                            </td>
                            <td className="px-6 py-4">
                                {user.countryOfPassport}
                            </td>
                            <td className="px-6 py-4">
                                {user.currentLocation}
                            </td>
                            <td className="px-6 py-4">
                                {user.educationFunding}
                            </td>
                            <td className="px-6 py-4">
                                {user.preferredStudyDestination}
                            </td>
                            <td className=" flex px-6 py-4">
                                <span
                                    className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 mr-5"
                                >
                                    {/* <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span> */}
                                    View
                                </span>
                                {
                                    user.contacedYet == true ?
                                        <div className="flex gap-2">
                                            <span
                                                className="inline-flex items-center gap-1 rounded-sm bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600 w-full">
                                                Mark Contacted
                                            </span>
                                        </div>
                                        :
                                        <div className="flex gap-2">
                                            <span
                                                className="inline-flex items-center gap-1 rounded-sm bg-blue-100 px-2 py-1 text-xs font-semibold text-blue-600 w-full">
                                                Mark Uncontacted
                                            </span>
                                        </div>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}