export const ContactData = {
    "question": [
        "Course",
        "Visa",
        "Language Preparation",
        "English test Voucher"
    ],
    "education": [
        "I have my own money (self funded)",
        "I am funded by parents or relatives",
        "I have a government scholarship",
        "I am looking for partial scholarship",
        "I have a student loan",
        "I have applied for a student loan"
    ],
    "destination": [
        "Australia",
        "Canada",
        "France",
        "Germany",
        "Japan",
        "New Zealand",
        "Singapore",
        "United Kingdom",
        "United States of America",
        "Help me decide"
    ],
    "countries": [
        "Afghanistan",
        "Åland Islands",
        "Albania",
        "Algeria",
        "American Samoa",
        "AndorrA",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo",
        "Congo, The Democratic Republic of the",
        "Cook Islands",
        "Costa Rica",
        "Cote D\'Ivoire",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands (Malvinas)",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and Mcdonald Islands",
        "Holy See (Vatican City State)",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran, Islamic Republic Of",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea, Democratic People\'S Republic of",
        "Korea, Republic of",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People\'S Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libyan Arab Jamahiriya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Macedonia, The Former Yugoslav Republic of",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia, Federated States of",
        "Moldova, Republic of",
        "Monaco",
        "Mongolia",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestinian Territory, Occupied",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russian Federation",
        "RWANDA",
        "Saint Helena",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia and Montenegro",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan, Province of China",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "United States Minor Outlying Islands",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Viet Nam",
        "Virgin Islands, British",
        "Virgin Islands, U.S.",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
    ],
    "codes": [
        {
          "id": "30784d77-34f2-4ef1-a25b-883f7efa1d8a",
          "name": "Afghanistan",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg",
          "code": "+93"
        },
        {
          "id": "89ab66f4-4f3d-4b36-9628-90bd1566f2b3",
          "name": "Åland Islands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AX.svg",
          "code": "+358"
        },
        {
          "id": "4c28dfd8-db3e-4a5d-8aad-3e076d90ffcc",
          "name": "Albania",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg",
          "code": "+355"
        },
        {
          "id": "e44d4d1d-9fe8-48d1-b152-041668d61adc",
          "name": "Algeria",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg",
          "code": "+213"
        },
        {
          "id": "28ac6977-6134-4535-b6a6-ce8682c667bb",
          "name": "American Samoa",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg",
          "code": "+1-684"
        },
        {
          "id": "ce54f7e6-0631-4e01-8c76-e6e4da376db0",
          "name": "Andorra",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg",
          "code": "+376"
        },
        {
          "id": "1336a21c-7125-49b5-9bc4-f9ed810c3190",
          "name": "Angola",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg",
          "code": "+244"
        },
        {
          "id": "2591a9ea-e833-470b-84e7-ebbcfbe302f3",
          "name": "Anguilla",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg",
          "code": "+1264"
        },
        {
          "id": "23a1e88e-1301-4e92-85f8-75cdd12ff38a",
          "name": "Antarctica",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg",
          "code": "+672"
        },
        {
          "id": "c2a4ac92-6845-4508-9b2e-85d7215f122d",
          "name": "Antigua & Barbuda",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg",
          "code": "+1-268"
        },
        {
          "id": "83e1625a-896f-4b63-8d8a-af7fae7efcd8",
          "name": "Argentina",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg",
          "code": "+54"
        },
        {
          "id": "e671e337-a894-486b-9101-dd5e6cf1e273",
          "name": "Armenia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg",
          "code": "+374"
        },
        {
          "id": "9c1ede25-1748-4db3-aea5-920c3c2e86a6",
          "name": "Aruba",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg",
          "code": "+297"
        },
        {
          "id": "47271051-f799-4d11-9bb5-c4d7855ada01",
          "name": "Ascension Island",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AC.svg",
          "code": "+247"
        },
        {
          "id": "e8af89b8-b573-40b1-bc4d-f4fafe435cca",
          "name": "Australia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg",
          "code": "+61"
        },
        {
          "id": "7fca41f5-70df-4aee-a98a-c41ef927b32c",
          "name": "Austria",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg",
          "code": "+43"
        },
        {
          "id": "7cc62a11-d9e1-4e27-8bcd-2f1941ee4fc0",
          "name": "Azerbaijan",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg",
          "code": "+994"
        },
        {
          "id": "305b44f0-f782-4001-b31c-a34d29778413",
          "name": "Bahamas",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg",
          "code": "+1242"
        },
        {
          "id": "f3b98788-80e1-4cff-ac14-03cbb9b11c13",
          "name": "Bahrain",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg",
          "code": "+973"
        },
        {
          "id": "2e3ce15c-0598-4589-826c-443243315c2d",
          "name": "Bangladesh",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg",
          "code": "+880"
        },
        {
          "id": "ce287589-8431-4895-b3e4-ab7a1eb1e033",
          "name": "Barbados",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg",
          "code": "+1246"
        },
        {
          "id": "2562825d-8323-4c0e-99b1-363aef1c4f71",
          "name": "Belarus",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg",
          "code": "+375"
        },
        {
          "id": "c550c612-28f9-4db9-a006-cb85a04a1c73",
          "name": "Belgium",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg",
          "code": "+32"
        },
        {
          "id": "4d8daeaf-d16b-45be-8ab6-137525fb68b5",
          "name": "Belize",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg",
          "code": "+501"
        },
        {
          "id": "b4c58b1c-fda3-4787-85d2-3100a01f3345",
          "name": "Benin",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg",
          "code": "+229"
        },
        {
          "id": "5e4e79c4-7779-47ef-a2af-8e2e6c1b50d6",
          "name": "Bermuda",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg",
          "code": "+1441"
        },
        {
          "id": "bc55fe2e-fb3c-486b-a4ba-d8946f2e37ff",
          "name": "Bhutan",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg",
          "code": "+975"
        },
        {
          "id": "9368e6b4-f1e5-4b4a-9523-aa4aed91445e",
          "name": "Bolivia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg",
          "code": "+591"
        },
        {
          "id": "a8f8276e-e0de-434c-a58c-6de44b3de1cc",
          "name": "Bosnia & Herzegovina",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg",
          "code": "+387"
        },
        {
          "id": "c9bb5e74-6564-4e43-8f89-11dc1650ef37",
          "name": "Botswana",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg",
          "code": "+267"
        },
        {
          "id": "0932de07-d993-4923-896a-db73fc92883b",
          "name": "Brazil",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg",
          "code": "+55"
        },
        {
          "id": "a488151a-d2c9-40e4-a56e-e51e73e451a5",
          "name": "British Indian Ocean Territory",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg",
          "code": "+246"
        },
        {
          "id": "62a014f6-f936-4e42-8df4-c5c4ef57e89b",
          "name": "British Virgin Islands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg",
          "code": "+1-284"
        },
        {
          "id": "1ef7fdf1-1ad7-433b-a8d8-44146d3234dd",
          "name": "Brunei",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg",
          "code": "+673"
        },
        {
          "id": "c6497385-7de0-4605-a506-cf36af938ee2",
          "name": "Bulgaria",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg",
          "code": "+359"
        },
        {
          "id": "9834e11b-bdf1-4dc3-8fba-a726012da3da",
          "name": "Burkina Faso",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg",
          "code": "+226"
        },
        {
          "id": "e40dc159-1618-4005-a4c0-e8ae26fafecd",
          "name": "Burundi",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg",
          "code": "+257"
        },
        {
          "id": "5940d856-d469-40a1-9858-59d209241797",
          "name": "Cambodia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg",
          "code": "+855"
        },
        {
          "id": "b2e2cc35-1b04-48f0-9bef-c1a029e0ec78",
          "name": "Cameroon",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg",
          "code": "+237"
        },
        {
          "id": "2e88dc74-1c56-4815-9c79-4f680df4abf5",
          "name": "Canada",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg",
          "code": "+1"
        },
        {
          "id": "594a0eda-4f8e-40dc-9386-26a5eda10213",
          "name": "Canary Islands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IC.svg",
          "code": "+34"
        },
        {
          "id": "f668bafc-b609-4b46-bef1-c9eac1cee3ff",
          "name": "Cape Verde",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg",
          "code": "+238"
        },
        {
          "id": "daebc2f5-62ac-403b-9c6d-9ae432f0788b",
          "name": "Caribbean Netherlands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg",
          "code": "+599"
        },
        {
          "id": "3e8dd586-d172-4bb0-adaa-b7443c638de8",
          "name": "Cayman Islands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg",
          "code": "+345"
        },
        {
          "id": "f65d00b0-611e-41e9-8808-ab45a96ad684",
          "name": "Central African Republic",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg",
          "code": "+236"
        },
        {
          "id": "8676da6a-8888-4575-b170-f25a079c721a",
          "name": "Ceuta & Melilla",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EA.svg",
          "code": "+34"
        },
        {
          "id": "8f99165c-a75b-49cf-8e18-2d73d3a14006",
          "name": "Chad",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg",
          "code": "+235"
        },
        {
          "id": "c81b3c56-66ee-40af-8572-6216ca1b673b",
          "name": "Chile",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg",
          "code": "+56"
        },
        {
          "id": "de5d8c7f-fc71-4cd3-b0c8-eefb109d79a3",
          "name": "China",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg",
          "code": "+86"
        },
        {
          "id": "b0dbcb42-fa0d-4e23-af30-1d1950a35f28",
          "name": "Christmas Island",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg",
          "code": "+61"
        },
        {
          "id": "752aedd5-a795-4c08-a774-b68a9d39b9d6",
          "name": "Cocos (Keeling) Islands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg",
          "code": "+61"
        },
        {
          "id": "4c9920d1-7842-4899-b686-db15bf14a743",
          "name": "Colombia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg",
          "code": "+57"
        },
        {
          "id": "85826ed9-0973-41bd-9210-981e430e76ef",
          "name": "Comoros",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg",
          "code": "+269"
        },
        {
          "id": "da1d5586-0d15-4242-aa55-8e6eba19d1ec",
          "name": "Congo - Brazzaville",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg",
          "code": "+242"
        },
        {
          "id": "a396c99d-49e0-41e2-9174-d1b705815bb4",
          "name": "Congo - Kinshasa",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg",
          "code": "+243"
        },
        {
          "id": "a70c94b0-e118-425e-8dd3-c2056997c992",
          "name": "Cook Islands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg",
          "code": "+682"
        },
        {
          "id": "18b400a7-0a74-4291-9242-058c6b55cea0",
          "name": "Costa Rica",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg",
          "code": "+506"
        },
        {
          "id": "a43e3c88-86d6-4d9c-bd90-5072152034a3",
          "name": "Côte d’Ivoire",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg",
          "code": "+225"
        },
        {
          "id": "98714c2f-0aed-4f37-8271-210bd1913304",
          "name": "Croatia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg",
          "code": "+385"
        },
        {
          "id": "e64a4288-a740-450a-951b-1e325c631d7a",
          "name": "Cuba",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg",
          "code": "+53"
        },
        {
          "id": "250be598-ee73-4ffa-a026-1338bfbb01be",
          "name": "Curaçao",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg",
          "code": "+599"
        },
        {
          "id": "6de3d35a-e512-44e8-82de-0961ed9cc56b",
          "name": "Cyprus",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg",
          "code": "+357"
        },
        {
          "id": "9cebd6f1-b0e8-4e03-a4e7-82a9fd302ddd",
          "name": "Czechia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg",
          "code": "+420"
        },
        {
          "id": "e9151e13-ee69-4325-8a69-680b68d01d38",
          "name": "Denmark",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg",
          "code": "+45"
        },
        {
          "id": "3c373559-d511-4ad5-b252-c417cb933604",
          "name": "Diego Garcia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DG.svg",
          "code": "+246"
        },
        {
          "id": "a757d806-8898-4024-a90d-134a39952eeb",
          "name": "Djibouti",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg",
          "code": "+253"
        },
        {
          "id": "1d1a6663-1aed-429c-b376-70fbfc7aa1b4",
          "name": "Dominica",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg",
          "code": "+1767"
        },
        {
          "id": "b2928157-faf9-4273-9722-0efddc39e401",
          "name": "Dominican Republic",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg",
          "code": "+1849"
        },
        {
          "id": "fbe12b1a-fd18-4a79-9b6c-b0bb841b1cc1",
          "name": "Ecuador",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg",
          "code": "+593"
        },
        {
          "id": "8b571c59-56b8-4dee-9b3b-b095bbec48a4",
          "name": "Egypt",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg",
          "code": "+20"
        },
        {
          "id": "c881cebf-cec1-4c4b-a0fd-684e0ecf0041",
          "name": "El Salvador",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg",
          "code": "+503"
        },
        {
          "id": "6f0254e6-0466-4c3e-a168-a4ad7bf810d1",
          "name": "England",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ENGLAND.svg",
          "code": "+44"
        },
        {
          "id": "f6bb3673-bf54-40a7-b79b-92dde76f8d18",
          "name": "Equatorial Guinea",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg",
          "code": "+240"
        },
        {
          "id": "8acbd2d3-8b32-416f-9edb-a977554ce0fe",
          "name": "Eritrea",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg",
          "code": "+291"
        },
        {
          "id": "7920df9e-3c27-4141-a031-b8dd60f5ff3c",
          "name": "Estonia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg",
          "code": "+372"
        },
        {
          "id": "90ae044b-23d4-4ce0-8223-87dadecdeec0",
          "name": "Eswatini",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg",
          "code": "+268"
        },
        {
          "id": "25f22d23-5e9d-45e9-b740-a369ca2aeaf5",
          "name": "Ethiopia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg",
          "code": "+251"
        },
        {
          "id": "e34294ea-2209-4140-96b6-e9c5512a49fa",
          "name": "European Union",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EU.svg",
          "code": "+388"
        },
        {
          "id": "d4940175-19ad-42b0-a0e3-239ed7baac08",
          "name": "Falkland Islands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg",
          "code": "+500"
        },
        {
          "id": "c3b02aa7-f613-41ec-a12e-717a5106afe0",
          "name": "Faroe Islands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg",
          "code": "+298"
        },
        {
          "id": "fcec8896-3957-47df-b3fc-8ff8ed9a8853",
          "name": "Fiji",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg",
          "code": "+679"
        },
        {
          "id": "86a98407-855b-4b4f-90b9-ee05203b8109",
          "name": "Finland",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg",
          "code": "+358"
        },
        {
          "id": "676963a9-cf29-41a8-a231-76e5b43fdb3f",
          "name": "France",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg",
          "code": "+33"
        },
        {
          "id": "a27fcef3-ccd9-4443-98b0-25a6b4dcc36f",
          "name": "French Guiana",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg",
          "code": "+594"
        },
        {
          "id": "17c83448-a18d-4ae3-96dc-c11ea0f6f7f4",
          "name": "French Polynesia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg",
          "code": "+689"
        },
        {
          "id": "15b3b3e4-af1b-40b5-b121-303cf8425e05",
          "name": "Gabon",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg",
          "code": "+241"
        },
        {
          "id": "68957a7c-d548-42b5-a629-2a570d472ec8",
          "name": "Gambia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg",
          "code": "+220"
        },
        {
          "id": "81565d34-16ed-42ec-aa6b-12e939f8ba9b",
          "name": "Georgia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg",
          "code": "+995"
        },
        {
          "id": "98b9c102-2d74-41c6-9a6e-c1347012c805",
          "name": "Germany",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg",
          "code": "+49"
        },
        {
          "id": "717a1497-3040-4d15-b193-ead34c2145cb",
          "name": "Ghana",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg",
          "code": "+233"
        },
        {
          "id": "ed35532f-b032-434a-9a2e-05d55c706bad",
          "name": "Gibraltar",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg",
          "code": "+350"
        },
        {
          "id": "b10032b6-db36-4b3c-8503-79365cb9eb8d",
          "name": "Greece",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg",
          "code": "+30"
        },
        {
          "id": "ffa2a2b7-779a-4b79-9881-7da04abc7652",
          "name": "Greenland",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg",
          "code": "+299"
        },
        {
          "id": "43dd53a4-ed5b-4537-bf1f-1e427bb07095",
          "name": "Grenada",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg",
          "code": "+1473"
        },
        {
          "id": "75f9398b-7b74-4042-bbf6-d2a3c91761ff",
          "name": "Guadeloupe",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg",
          "code": "+590"
        },
        {
          "id": "0b5cb0c7-78aa-410d-8f10-52d47caff60d",
          "name": "Guam",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg",
          "code": "+1671"
        },
        {
          "id": "7487cc0b-fe84-4e83-9fd6-0f5b21a97ce4",
          "name": "Guatemala",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg",
          "code": "+502"
        },
        {
          "id": "9a5e65a4-ae1c-434c-bda6-a06712eab971",
          "name": "Guernsey",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg",
          "code": "+44"
        },
        {
          "id": "79019d20-4f6d-485f-97cb-1a50956255f6",
          "name": "Guinea",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg",
          "code": "+224"
        },
        {
          "id": "30cf0d6d-dfe7-4bb3-a40d-936cbc2114c4",
          "name": "Guinea-Bissau",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg",
          "code": "+245"
        },
        {
          "id": "f5fa3bec-5c24-42ce-8c5f-497c39d42be4",
          "name": "Guyana",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg",
          "code": "+595"
        },
        {
          "id": "ac9f4431-87c6-4820-9210-3c72902bd53f",
          "name": "Haiti",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg",
          "code": "+509"
        },
        {
          "id": "baf99809-28d9-47bf-95f1-40218ab33265",
          "name": "Heard & McDonald Islands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HM.svg",
          "code": "+672"
        },
        {
          "id": "d0967e5c-47c6-4175-b6a4-703ee848583b",
          "name": "Honduras",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg",
          "code": "+504"
        },
        {
          "id": "4794b1ca-973d-4563-9216-e31d5f29d74f",
          "name": "Hong Kong SAR China",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg",
          "code": "+852"
        },
        {
          "id": "3a8596e4-71e7-4332-af1a-3b54cffc8e48",
          "name": "Hungary",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg",
          "code": "+36"
        },
        {
          "id": "9a2f4d9c-2a0c-427d-86e9-a54b4a21719a",
          "name": "Iceland",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg",
          "code": "+354"
        },
        {
          "id": "d7285a4e-94ce-4fac-9d0e-60653358da25",
          "name": "India",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg",
          "code": "+91"
        },
        {
          "id": "3212771f-1295-4089-99c7-50be2fd2f9e1",
          "name": "Indonesia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg",
          "code": "+62"
        },
        {
          "id": "7c09ba8a-ac5b-44cb-a947-1caf6776f0af",
          "name": "Iran",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg",
          "code": "+98"
        },
        {
          "id": "51bafcbf-1a4e-4534-bc75-31b260152c6f",
          "name": "Iraq",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg",
          "code": "+964"
        },
        {
          "id": "cc40eb07-f536-4a9f-b949-ac832ddbb5da",
          "name": "Ireland",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg",
          "code": "+353"
        },
        {
          "id": "ac13ed58-762e-43ec-acdc-c66d89cf9d9b",
          "name": "Isle of Man",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg",
          "code": "+44"
        },
        {
          "id": "606b867a-af99-469d-8ec5-c02f3f9f6c9b",
          "name": "Israel",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg",
          "code": "+972"
        },
        {
          "id": "ea027cc6-3481-45f3-a61d-d94116dbb04c",
          "name": "Italy",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg",
          "code": "+39"
        },
        {
          "id": "81e54dc6-c9ad-4a1c-974d-8069a13c6637",
          "name": "Jamaica",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg",
          "code": "+1876"
        },
        {
          "id": "e6ed74b0-a70d-48df-ae51-3704a29bc763",
          "name": "Japan",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg",
          "code": "+81"
        },
        {
          "id": "50e82cbe-efc5-4f8b-96b4-b83ba7454bdc",
          "name": "Jersey",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg",
          "code": "+44"
        },
        {
          "id": "b9b28053-7680-4127-918b-1c1ccff43a16",
          "name": "Jordan",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg",
          "code": "+962"
        },
        {
          "id": "490e05ed-9a11-4d2d-b8ec-23cbfa3e6235",
          "name": "Kazakhstan",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg",
          "code": "+77"
        },
        {
          "id": "4a12c930-9551-4f5a-96cd-57d6d3f8f159",
          "name": "Kenya",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg",
          "code": "+254"
        },
        {
          "id": "716a6a1a-8547-4ccc-965c-e77a191f0614",
          "name": "Kiribati",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg",
          "code": "+686"
        },
        {
          "id": "5c0f21ac-abdc-4cf2-a644-e4e883a88551",
          "name": "Kosovo",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/XK.svg",
          "code": "+383"
        },
        {
          "id": "6ef051d8-d6a1-4e88-828d-eff5900d7ef1",
          "name": "Kuwait",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg",
          "code": "+965"
        },
        {
          "id": "bfe372ee-007c-4235-99d2-5db82f2fb46d",
          "name": "Kyrgyzstan",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg",
          "code": "+996"
        },
        {
          "id": "c35d6a7d-c05c-4a05-b9d5-a351b7b6121f",
          "name": "Laos",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg",
          "code": "+856"
        },
        {
          "id": "9b455435-5957-4f05-abe7-9c6d379fe474",
          "name": "Latvia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg",
          "code": "+371"
        },
        {
          "id": "6a572bd5-5421-417e-8cd0-259c2ef9a7dc",
          "name": "Lebanon",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg",
          "code": "+961"
        },
        {
          "id": "454d309b-e49e-47b6-baec-bb414a4681e4",
          "name": "Lesotho",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg",
          "code": "+266"
        },
        {
          "id": "63d25a48-419d-4e5b-8e6f-8e8e27f7c479",
          "name": "Liberia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg",
          "code": "+231"
        },
        {
          "id": "c9fdf98c-307d-4388-a172-cf5bd024b50a",
          "name": "Libya",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg",
          "code": "+218"
        },
        {
          "id": "6d64ea0d-0b3e-43e7-b1b8-9320bc290e98",
          "name": "Liechtenstein",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg",
          "code": "+423"
        },
        {
          "id": "fb471526-ba92-47c1-92e4-17378e5f9bc8",
          "name": "Lithuania",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg",
          "code": "+370"
        },
        {
          "id": "e13c9252-4418-4a31-b031-3ab335b291c5",
          "name": "Luxembourg",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg",
          "code": "+352"
        },
        {
          "id": "b93f2fab-5a0e-4190-83c7-8b5a41f0c112",
          "name": "Macao SAR China",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg",
          "code": "+853"
        },
        {
          "id": "2fb1b101-1152-4dbe-addc-4545e976c707",
          "name": "Madagascar",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg",
          "code": "+261"
        },
        {
          "id": "5ba36383-d953-46ab-9c94-44e39110a4e6",
          "name": "Malawi",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg",
          "code": "+265"
        },
        {
          "id": "f30f0045-a0fb-49f5-8805-5b9df969af74",
          "name": "Malaysia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg",
          "code": "+60"
        },
        {
          "id": "7ccd3b0a-140f-4df8-8c72-813d611eddd8",
          "name": "Maldives",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg",
          "code": "+960"
        },
        {
          "id": "906c1dde-2991-4e08-b4c0-9ba06b5cc5f7",
          "name": "Mali",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg",
          "code": "+223"
        },
        {
          "id": "7a195985-1f6f-444c-93ec-2fe3fbaafe55",
          "name": "Malta",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg",
          "code": "+356"
        },
        {
          "id": "76b711cb-4603-426c-942e-fa96ca502fe8",
          "name": "Marshall Islands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg",
          "code": "+692"
        },
        {
          "id": "dd18b16f-3d5a-42a2-a258-42fdc44764bd",
          "name": "Martinique",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg",
          "code": "+596"
        },
        {
          "id": "4b5287d8-1928-4103-9110-03130c5098be",
          "name": "Mauritania",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg",
          "code": "+222"
        },
        {
          "id": "471139df-0bcd-459b-8c71-8ffa2459ff1a",
          "name": "Mauritius",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg",
          "code": "+230"
        },
        {
          "id": "5cb030d1-e97d-4b60-bf8d-912e65ff5efa",
          "name": "Mayotte",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg",
          "code": "+262"
        },
        {
          "id": "0641020c-8abb-4adc-ae3f-ac3ffb157257",
          "name": "Mexico",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg",
          "code": "+52"
        },
        {
          "id": "0934cfd2-3595-41cb-b933-6c55df6effe3",
          "name": "Micronesia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg",
          "code": "+691"
        },
        {
          "id": "25d374fb-0307-40e2-a7b2-3b8e7ff29a36",
          "name": "Moldova",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg",
          "code": "+373"
        },
        {
          "id": "09669309-fc7d-4a18-b592-2cea51fbdcbb",
          "name": "Monaco",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg",
          "code": "+377"
        },
        {
          "id": "3b5bce29-6205-40bc-8b54-8399e8e0aad2",
          "name": "Mongolia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg",
          "code": "+976"
        },
        {
          "id": "bdd6b06e-0e55-4806-8c57-a7c5ec49e2e2",
          "name": "Montenegro",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg",
          "code": "+382"
        },
        {
          "id": "29430329-3dd0-4f02-b28a-bf2303886f10",
          "name": "Montserrat",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg",
          "code": "+1664"
        },
        {
          "id": "5aee1e61-c537-4ba3-952e-f95d754abcb4",
          "name": "Morocco",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg",
          "code": "+212"
        },
        {
          "id": "1979452f-3638-42ef-b817-1ed464f08a4a",
          "name": "Mozambique",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg",
          "code": "+258"
        },
        {
          "id": "57f5de01-7638-4cc1-adce-480e5e657b81",
          "name": "Myanmar (Burma)",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg",
          "code": "+95"
        },
        {
          "id": "0a497fe6-dd5d-4f70-8365-06600bedcd5b",
          "name": "Namibia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg",
          "code": "+264"
        },
        {
          "id": "710bea3a-b4d9-4271-8a28-8f0ea36c8abe",
          "name": "Nauru",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg",
          "code": "+674"
        },
        {
          "id": "3df5d74a-61e3-4fd2-a90d-0539abc23092",
          "name": "Nepal",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg",
          "code": "+977"
        },
        {
          "id": "d67ea62a-2a9f-4c7c-b18d-b2cb796d19ad",
          "name": "Netherlands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg",
          "code": "+31"
        },
        {
          "id": "469b1cff-4221-4c30-abab-18c8c9ae6bcb",
          "name": "New Caledonia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg",
          "code": "+687"
        },
        {
          "id": "8597c674-80a3-4e35-82d3-53f61553a774",
          "name": "New Zealand",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg",
          "code": "+64"
        },
        {
          "id": "ff5e5d7d-2bf5-4a53-ab6d-d53d9987a53a",
          "name": "Nicaragua",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg",
          "code": "+505"
        },
        {
          "id": "b58527db-e88f-469a-8e9f-f7d0b3ea4f58",
          "name": "Niger",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg",
          "code": "+227"
        },
        {
          "id": "a644a070-d422-4af5-9b3f-a0a1ea85ac98",
          "name": "Nigeria",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg",
          "code": "+234"
        },
        {
          "id": "63ff597f-99b3-44c6-af5c-ff52ab73bc73",
          "name": "Niue",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg",
          "code": "+683"
        },
        {
          "id": "5f5bd6db-a4b1-4f32-8f77-2eb28b5b41c7",
          "name": "Norfolk Island",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg",
          "code": "+672"
        },
        {
          "id": "8d816327-0aaa-4b55-8678-bb19b0e4be95",
          "name": "North Korea",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg",
          "code": "+850"
        },
        {
          "id": "8b8b8da2-9046-4f93-b695-fac1faaf15df",
          "name": "North Macedonia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg",
          "code": "+389"
        },
        {
          "id": "d7f6f72d-c629-4d7b-8791-0af4da31a90f",
          "name": "Northern Mariana Islands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg",
          "code": "+1670"
        },
        {
          "id": "7dd2173f-b34b-464f-9b60-712b11e84bc1",
          "name": "Norway",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg",
          "code": "+47"
        },
        {
          "id": "d819a6f6-9558-4bae-91ac-722255db4591",
          "name": "Oman",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg",
          "code": "+968"
        },
        {
          "id": "f7461d23-5bc7-4ceb-be5b-b5c9ac834c65",
          "name": "Pakistan",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg",
          "code": "+92"
        },
        {
          "id": "33e081e2-0e37-4f3d-8013-9b29d0e3ef43",
          "name": "Palau",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg",
          "code": "+680"
        },
        {
          "id": "ff97ee47-4002-426e-863f-a731881a2853",
          "name": "Palestinian Territories",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg",
          "code": "+970"
        },
        {
          "id": "b18ce034-a622-4449-96f9-7c310c9b2ae2",
          "name": "Panama",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg",
          "code": "+507"
        },
        {
          "id": "e789462f-3161-4539-ab66-8e36e023579d",
          "name": "Papua New Guinea",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg",
          "code": "+675"
        },
        {
          "id": "d23fefde-5c49-4c10-a657-65b21e9d0c8b",
          "name": "Paraguay",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg",
          "code": "+595"
        },
        {
          "id": "9c2961c9-4c93-4e47-9c25-9344664c6117",
          "name": "Peru",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg",
          "code": "+51"
        },
        {
          "id": "15e2d548-71a9-4665-8d84-0fd2e79b3a84",
          "name": "Philippines",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg",
          "code": "+63"
        },
        {
          "id": "fdbc8b9c-2dd8-4759-be62-b0988406bcd2",
          "name": "Pitcairn Islands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg",
          "code": "+870"
        },
        {
          "id": "b395bae5-fbe4-4df1-963b-841f43ac9b4c",
          "name": "Poland",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg",
          "code": "+48"
        },
        {
          "id": "89b623d7-71a5-4ab1-b540-90d934dd80f0",
          "name": "Portugal",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg",
          "code": "+351"
        },
        {
          "id": "f527870d-17ee-4558-8326-21a91e062b77",
          "name": "Puerto Rico",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg",
          "code": "+1939"
        },
        {
          "id": "ae8307f0-a229-40e3-b5a6-baf33e4336f6",
          "name": "Qatar",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg",
          "code": "+974"
        },
        {
          "id": "736ca559-c54b-492e-a4f2-201c175981ed",
          "name": "Réunion",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg",
          "code": "+262"
        },
        {
          "id": "5490951e-adbd-4e98-b2b0-7572a70b9383",
          "name": "Romania",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg",
          "code": "+40"
        },
        {
          "id": "f3ab3b17-e198-4ff6-ac89-35e2794202d3",
          "name": "Russia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg",
          "code": "+7"
        },
        {
          "id": "a0e31871-8025-43a1-8330-6cefb856d7b0",
          "name": "Rwanda",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg",
          "code": "+250"
        },
        {
          "id": "8482becc-cc91-4f8c-8de5-2ad58194f487",
          "name": "Samoa",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg",
          "code": "+685"
        },
        {
          "id": "efb24521-a78f-46e6-b998-10f226b570c0",
          "name": "San Marino",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg",
          "code": "+378"
        },
        {
          "id": "5319e5f3-89d9-4dac-a695-9ee7a9bf78f5",
          "name": "São Tomé & Príncipe",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg",
          "code": "+239"
        },
        {
          "id": "4dd41d03-3fbc-476c-85ff-3ce9f74d604e",
          "name": "Saudi Arabia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg",
          "code": "+966"
        },
        {
          "id": "cdc85959-2237-4029-8ad7-4ba1fce29698",
          "name": "Scotland",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SCOTLAND.svg",
          "code": "+44"
        },
        {
          "id": "6aba6288-95d2-461d-b492-39ee4ca62df1",
          "name": "Senegal",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg",
          "code": "+221"
        },
        {
          "id": "7c8372ab-00b9-48fe-9d96-5f38afdef6cb",
          "name": "Serbia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg",
          "code": "+381"
        },
        {
          "id": "6ae5c83a-588d-4edf-b6aa-220670a7d5ef",
          "name": "Seychelles",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg",
          "code": "+248"
        },
        {
          "id": "ccddf81a-dde1-42b5-a790-4d93634be1be",
          "name": "Sierra Leone",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg",
          "code": "+232"
        },
        {
          "id": "9d922f6e-64e2-4b4d-92a8-34c550d79675",
          "name": "Singapore",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg",
          "code": "+65"
        },
        {
          "id": "0040ff22-1410-440b-8953-caf38feae754",
          "name": "Sint Maarten",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg",
          "code": "+1-721"
        },
        {
          "id": "46ad7d02-eead-4679-9794-0eba9c9d5df7",
          "name": "Slovakia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg",
          "code": "+421"
        },
        {
          "id": "d4f4e994-a958-4831-ae12-12aa061966c1",
          "name": "Slovenia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg",
          "code": "+386"
        },
        {
          "id": "cf08bd8c-a81d-462c-874f-41be415a68ce",
          "name": "Solomon Islands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg",
          "code": "+677"
        },
        {
          "id": "56089b68-1b78-4abf-bb17-5d4d332aa3db",
          "name": "Somalia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg",
          "code": "+252"
        },
        {
          "id": "180af267-2228-49e4-ba97-e42e1fed4a6a",
          "name": "South Africa",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg",
          "code": "+27"
        },
        {
          "id": "08243866-6649-442f-b52d-9caeff54a6e7",
          "name": "South Korea",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg",
          "code": "+82"
        },
        {
          "id": "b4dbee63-f88b-4211-adee-70af7eeb3c45",
          "name": "South Sudan",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg",
          "code": "+211"
        },
        {
          "id": "28ee8c7f-6ed7-4363-9ab4-ce26493b7849",
          "name": "Spain",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg",
          "code": "+34"
        },
        {
          "id": "9dcae6e9-a22f-4280-a0b7-345bd58cd341",
          "name": "Sri Lanka",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg",
          "code": "+94"
        },
        {
          "id": "b030e7bb-c728-46dd-89e0-995025f86e85",
          "name": "St. Barthélemy",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg",
          "code": "+590"
        },
        {
          "id": "e21e20a1-8e6f-4d4e-9148-9f4c9cc7b54e",
          "name": "St. Helena",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg",
          "code": "+290"
        },
        {
          "id": "ee9feb03-0506-4019-b11b-0e5139ec52db",
          "name": "St. Kitts & Nevis",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg",
          "code": "+1-869"
        },
        {
          "id": "01ad50f5-9828-4eb6-97c1-1abd711d8962",
          "name": "St. Lucia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg",
          "code": "+1-758"
        },
        {
          "id": "6ca56f9b-48f5-4e10-a551-d539909cb4ab",
          "name": "St. Martin",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg",
          "code": "+590"
        },
        {
          "id": "79cbdd7b-4722-4ad5-bad9-78065b5a2a5a",
          "name": "St. Pierre & Miquelon",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg",
          "code": "+508"
        },
        {
          "id": "e8ebee62-64d2-42d9-ac79-d25218b26823",
          "name": "St. Vincent & Grenadines",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg",
          "code": "+1-784"
        },
        {
          "id": "13ed6693-fed6-4a27-8777-8bddbbf25255",
          "name": "Sudan",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg",
          "code": "+249"
        },
        {
          "id": "b3e6f87f-781c-409f-be8f-d93ae304a115",
          "name": "Suriname",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg",
          "code": "+597"
        },
        {
          "id": "af1b30de-fbbd-4a47-9652-1d671c5f56f7",
          "name": "Sweden",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg",
          "code": "+46"
        },
        {
          "id": "9dc99b04-4821-4838-ad79-0d164a50f72e",
          "name": "Switzerland",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg",
          "code": "+41"
        },
        {
          "id": "f9d2b661-b7bd-498f-98ab-a5795aa98c74",
          "name": "Syria",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg",
          "code": "+963"
        },
        {
          "id": "ea657d8c-5374-4d35-a989-aa377755b796",
          "name": "Taiwan",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg",
          "code": "+886"
        },
        {
          "id": "8b87969e-99e8-4b4d-80e3-18974b8f4cbc",
          "name": "Tajikistan",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg",
          "code": "+992"
        },
        {
          "id": "36652d62-242e-495f-9af7-d7e4c10d3708",
          "name": "Tanzania",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg",
          "code": "+255"
        },
        {
          "id": "8da6237f-344b-48ad-9f46-8dec60c62a35",
          "name": "Thailand",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg",
          "code": "+66"
        },
        {
          "id": "4c0a83bf-9ac8-4ef4-920b-c6698a86aabe",
          "name": "Timor-Leste",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg",
          "code": "+670"
        },
        {
          "id": "f30482bb-5884-4441-8dd6-35558bef66c9",
          "name": "Togo",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg",
          "code": "+228"
        },
        {
          "id": "6c1fd7e9-e19c-4f4e-88a9-223273de298e",
          "name": "Tokelau",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg",
          "code": "+690"
        },
        {
          "id": "dfd0f18f-97d5-4ec8-83c8-66bdfe476891",
          "name": "Tonga",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg",
          "code": "+676"
        },
        {
          "id": "db28d7af-6d6b-4da3-ae4d-4066770b7a81",
          "name": "Trinidad & Tobago",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg",
          "code": "+1-868"
        },
        {
          "id": "7fcb23af-7059-4898-86af-56ecf5a81aef",
          "name": "Tunisia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg",
          "code": "+216"
        },
        {
          "id": "ca23236f-7b9e-42e7-9a51-a9497462e9cd",
          "name": "Turkey",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg",
          "code": "+90"
        },
        {
          "id": "c608ad65-ece4-4eaf-a9d4-07df9fef34d8",
          "name": "Turkmenistan",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg",
          "code": "+993"
        },
        {
          "id": "bb9096d3-8771-4569-adec-715dd50d3a69",
          "name": "Turks & Caicos Islands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg",
          "code": "+1-649"
        },
        {
          "id": "7d5ce689-1d1f-43ff-882b-7759496f33fa",
          "name": "Tuvalu",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg",
          "code": "+688"
        },
        {
          "id": "619ca349-5877-4299-96d4-dd4d056f1c80",
          "name": "U.S. Virgin Islands",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg",
          "code": "+1-340"
        },
        {
          "id": "d6c2b11c-b515-4535-af69-45ccaab9b584",
          "name": "Uganda",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg",
          "code": "+256"
        },
        {
          "id": "a742bcd4-7d44-41a3-8d27-e0256f2b1fd9",
          "name": "Ukraine",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg",
          "code": "+380"
        },
        {
          "id": "90181449-f8c9-46c0-8954-3f79543dadb0",
          "name": "United Arab Emirates",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg",
          "code": "+971"
        },
        {
          "id": "2c455fe1-f71c-41c3-bbf1-752cc916dc83",
          "name": "United Kingdom",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg",
          "code": "+44"
        },
        {
          "id": "7151faf1-fe08-4729-83c0-f91c4cc769a6",
          "name": "United States",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg",
          "code": "+1"
        },
        {
          "id": "c91e6363-4fb4-45ba-9336-f76c2ea94661",
          "name": "Uruguay",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg",
          "code": "+598"
        },
        {
          "id": "95bd2f9b-e662-42e3-b96d-8264e1091a09",
          "name": "Uzbekistan",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg",
          "code": "+998"
        },
        {
          "id": "c54d6602-7468-437b-90f4-beb610974e5a",
          "name": "Vanuatu",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg",
          "code": "+678"
        },
        {
          "id": "7d6f5b7a-f751-4d44-a546-6d87334439c8",
          "name": "Vatican City",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg",
          "code": "+379"
        },
        {
          "id": "e9159658-1ecf-4302-b747-be36b2eb6b45",
          "name": "Venezuela",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg",
          "code": "+58"
        },
        {
          "id": "a62210c4-6216-4e23-9eb4-4f4ba55c35e0",
          "name": "Vietnam",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg",
          "code": "+84"
        },
        {
          "id": "b0042790-369e-4756-b727-4ca74fa9c8a7",
          "name": "Wales",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WALES.svg",
          "code": "+44"
        },
        {
          "id": "be2e7bbc-90d1-4c40-bba4-b707f7768f72",
          "name": "Wallis & Futuna",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg",
          "code": "+681"
        },
        {
          "id": "eed56b02-e754-4b5b-af7f-c87ca5509865",
          "name": "Western Sahara",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EH.svg",
          "code": "+212"
        },
        {
          "id": "908e0b49-475c-4fc8-a036-70c742123cbd",
          "name": "Yemen",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg",
          "code": "+967"
        },
        {
          "id": "808d2f27-4bf5-4ee0-8de7-800743459e95",
          "name": "Zambia",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg",
          "code": "+260"
        },
        {
          "id": "b74f704c-6d8e-495a-bba5-4ba2bfe1fe1e",
          "name": "Zimbabwe",
          "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg",
          "code": "+263"
        }
      ]
}