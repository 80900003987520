import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import '../../App.css';
import { Variables } from './../../data/Variables';
import { ContactData } from '../../data/contactus';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function CountryCodes({ selectcode }) {
  const [dialCode, setDialCode] = useState(ContactData.codes);
  const [selected, setSelected] = useState(ContactData.codes[107])
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     // setLoading(true);
  //     try {
  //       const response = await axios.get(Variables.PRODUCTION_URL + 'api/Dialcodes/getcodes');
  //       setDialCode(response.data);
  //       setSelected(response.data[107]);
  //     } catch (err) {
  //       // setError(err);
  //       console.log(err);
  //     } finally {
  //       // setLoading(false);
  //     }
  //   };
  //   fetchData();
  // }, []);
  useEffect(() => {
    selectcode(selected.code);

  }, [selected])

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <div className='absolute'>
          <div className="relative">
            <Listbox.Button className="relative cursor-default  bg-white border border-gray-200  text-gray-600 slightly pt-1 px-2 rounded-2xl leading-tight focus:outline-none focus:bg-white text-left sm:text-sm">
              <span className="flex items-center">
                <img src={selected.image} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                <span className="ml-2 block truncate text-xs" >{selected.code}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                {/* <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 min-w-min overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {Object.values(dialCode).map((country) =>
                  <Listbox.Option
                    key={country.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-2'
                      )
                    }
                    value={country}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <img src={country.image} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                          <span
                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                          >
                            {country.name}
                          </span>
                          <span
                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                          >
                            {country.code}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  )
}