import { Variables } from '../../data/Variables';
import { useEffect, useState } from 'react';
import axios from 'axios';
import '../../App.css';
import Navbar from './../Components/navbar';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';

const navigation = [
    { name: 'Contacts', href: '#', current: true },
    { name: 'Partners', href: '#', current: false },
    { name: 'Carrers', href: '#', current: false },
    { name: 'Home Page', href: '#', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function StudentServices() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    const headers = {
        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
    };
    const [data, setData] = useState({});

    return (
        <div className="">
            <div className='fixed top-0 left-0 right-0 z-50'>
                <Navbar />
            </div>
            <Navbar />
            {isMobile ?
                <div  className="bg-gradient-to-r flex flex-col from-red-300 to-pink-600 p-10 pb-0">
                    <div  data-aos="fade-right" className='flex flex-col justify-center items-center mx-auto'>
                        <h1 className=' text-white text-center text-3xl mb-10'>Experience the diverse culture & incredible landscape of Australia</h1>
                    </div>
                    <img  data-aos="fade-right" src='/images/students/header.png' className=' h-fit' />
                </div>
                :
                <div className="bg-gradient-to-r flex flex-row from-red-300 to-pink-600">
                    <img  data-aos="fade-right" src='/images/students/header.png' className='h-80' />
                    <div  data-aos="fade-right" className='flex flex-col justify-center items-center mx-auto'>
                        <h1 className=' text-white text-3xl mb-10'>Experience the diverse culture & incredible landscape of Australia</h1>
                        <p className='text-white text-center font-medium'>Australia is a modern, democratic country with great healthcare,<br />
                            excellent educational institutions and a geography that’s like no place else on earth.</p>
                    </div>
                </div>
            }
            <div className=" flex flex-col p-10 pt-20 justify-center items-center">
                <h1  data-aos="fade-up" className=' text-black text-3xl mb-10'>Why Study in Abroad?</h1>
                <p  data-aos="fade-up" className={isMobile ? '' : ' w-3/4 ' + 'text-black text-center font-medium '}>The world is full of wonder and diversity, and each day presents an opportunity to learn something new. Whether it's exploring a new culture, discovering a new skill, or simply enjoying the beauty of nature, life is a journey of discovery. By embracing curiosity and an open mind, we can enrich our lives and connect with the world around us. Every experience, no matter how big or small, can teach us something valuable and help us grow as individuals. So let's savor each moment and embrace the richness of life.</p>
                <Link  data-aos="fade-up" to='/contact' className='mt-10 bg-gradient-to-r text-white p-5 py-4 rounded-xs from-orange-500 to-pink-500'>Contact Us To Study In Abroad</Link>
            </div>
            {
                isMobile ?
                    <div className='flex flex-col justify-center items-center bg-gray-200'>

                        <div  data-aos="fade-right" className='flex flex-col p-10 bg-gray-100 pt-20'>
                            <img src='/images/students/1.png' className='w-full' />
                            <div>
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Step 1 – We listen to you</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>Tell us about your education goals, your interests and skill sets, as well as your preferred lifestyle location and we’ll suggest some great options, study locations and advise you on courses, career paths, available scholarships and possible financial assistance for international students.
                                </p>
                            </div>
                        </div>
                        <div  data-aos="fade-left" className='flex flex-col p-10'>
                            <div>
                                <img src='/images/students/2.png' className='w-full' />
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Step 2 – Then we work out the details</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>Once we’ve worked out your personal and professional goals, we’ll go into detail to find the universities and institutes that can offer you courses aligned with your career path and chosen city. We’ll check that you meet the entry requirements and guide about visa requirements.
                                </p>
                            </div>
                        </div>
                        <div  data-aos="fade-right" className='flex flex-col p-10 bg-gray-100'>
                            <img src='/images/students/3.png' className='w-full' />
                            <div>
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Step 3 – Address any visa issues</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>Our team of visa documentation experts will make sure you’re covered by an appropriate study visa to continue your overseas studies, as well as ensure that you meet all the requirements.
                                </p>
                            </div>
                        </div>
                        <div  data-aos="fade-left" className='flex flex-col p-10'>
                            <div>
                                <img src='/images/students/4.png' className='w-full' />
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Step 4 – We’ll handle the application process</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>There’s no need to worry about the paperwork, we’ll take care of everything for you, liaise with the authorities and your educational provider to make sure everything runs smoothly.
                                </p>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='flex flex-col justify-center items-center bg-gray-200'>

                        <div  data-aos="fade-right" className='flex flex-row w-2/3 p-10 bg-gray-100 mt-20 items-center'>
                            <img src='/images/students/1.png' className='h-96 mr-10' />
                            <div>
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Step 1 – We listen to you</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>Tell us about your education goals, your interests and skill sets, as well as your preferred lifestyle location and we’ll suggest some great options, study locations and advise you on courses, career paths, available scholarships and possible financial assistance for international students.
                                </p>
                            </div>
                        </div>
                        <div  data-aos="fade-left" className='flex flex-row w-2/3 p-10 items-center'>
                            <div>
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Step 2 – Then we work out the details</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>Once we’ve worked out your personal and professional goals, we’ll go into detail to find the universities and institutes that can offer you courses aligned with your career path and chosen city. We’ll check that you meet the entry requirements and guide about visa requirements.
                                </p>
                            </div>
                            <img src='/images/students/2.png' className='h-96 ml-10' />
                        </div>
                        <div  data-aos="fade-right" className='flex flex-row w-2/3 p-10 bg-gray-100 items-center'>
                            <img src='/images/students/3.png' className='h-96 mr-10' />
                            <div>
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Step 3 – Address any visa issues</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>Our team of visa documentation experts will make sure you’re covered by an appropriate study visa to continue your overseas studies, as well as ensure that you meet all the requirements.
                                </p>
                            </div>
                        </div>
                        <div  data-aos="fade-left" className='flex flex-row w-2/3 p-10 items-center'>
                            <div>
                                <h1 className='text-4xl font-semibold text-gray-800 mb-10 mt-5'>Step 4 – We’ll handle the application process</h1>
                                <p className=' text-gray-600 mt-5 text-base leading-7'>There’s no need to worry about the paperwork, we’ll take care of everything for you, liaise with the authorities and your educational provider to make sure everything runs smoothly.
                                </p>
                            </div>
                            <img src='/images/students/4.png' className='h-96 ml-10' />
                        </div>
                    </div>
            }
            <Footer />
        </div>
    )
}