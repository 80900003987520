import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./app/Components/home";
import Contact from "./app/Pages/Contact";
import LoginPage from './app/Pages/login';
import Admin from './app/Pages/Admin';
import AboutUs from "./app/Pages/AboutUs";
import StudentServices from './app/Pages/StudentServices';
import StudyAbroad from "./app/Pages/StudyAbroad";

function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mod" element={<LoginPage />} />
        <Route path="/home" element={<Navigate to="/" />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/student" element={<StudentServices />} />
        <Route path="/study" element={<StudyAbroad />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;