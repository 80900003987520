import '../../App.css';
import ReactDOM from 'react-dom';
import React, { useState, useEffect } from 'react';
import Navbar from './navbar';
import { Link, NavLink, Route, useHistory, BrowserRouter as Router } from "react-router-dom";
import ContactUs from './contactUs';
import FuturePortal from './FuturePortal';
import Strip from './Strip';
import Footer from './Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Home() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);

    const data = [
        {
            Image: "/images/global/1.webp", heading: "Education Counselling", text: "Our Education Counsellors will learn about your career aspirations, lifestyle and study goals so that we can offer tailored advice.",
            url: "/student"
        },
        {
            Image: "/images/global/2.webp", heading: "Education Counselling", text: "Our Education Counsellors will learn about your career aspirations, lifestyle and study goals so that we can offer tailored advice.",
            url: "/student"
        },
        {
            Image: "/images/global/3.webp", heading: "Education Counselling", text: "Our Education Counsellors will learn about your career aspirations, lifestyle and study goals so that we can offer tailored advice.",
            url: "/student"
        },
    ];
    const data2 = [
        {
            Image: "/images/global/4.webp", heading: "Education Counselling", text: "Our Education Counsellors will learn about your career aspirations, lifestyle and study goals so that we can offer tailored advice.",
            url: "/student"
        },
        {
            Image: "/images/global/5.webp", heading: "Education Counselling", text: "Our Education Counsellors will learn about your career aspirations, lifestyle and study goals so that we can offer tailored advice.",
            url: "/student"
        },
        {
            Image: "/images/global/6.webp", heading: "Education Counselling", text: "Our Education Counsellors will learn about your career aspirations, lifestyle and study goals so that we can offer tailored advice.",
            url: "/student"
        }
    ];
    return (
        <div className="App">
            <div className='fixed top-0 left-0 right-0 z-50'>
                <Navbar />
            </div>
            <Navbar />
            <div>
                <div
                    className={isMobile ?
                        'w-fit conatiner bg-blue-300 flex flex-col'
                        :
                        'conatiner w-auto bg-blue-300 flex flex-row justify-around'
                    }>
                    <div className='flex flex-col justify-center item-center'>
                        {isMobile ?
                            <div>
                                <h1 data-aos="fade-up" className=" font-custom text-3xl text-gray-800 mt-10 mx-auto">The future you imagine is</h1>
                                <h1 data-aos="fade-up" className=" font-custom text-3xl text-gray-800 mx-auto">closer than you think.</h1>
                            </div>
                            :
                            <h1 data-aos="fade-up" className="font-custom text-5xl font-bold text-gray-800  w-96">The future you imagine is closer than you think.</h1>
                        }
                        <Link data-aos="fade-left"
                            to="/contact"
                            className={isMobile ?
                                "bg-blue-400 hover:bg-blue-700 hover:text-white text-gray-800 font-bold py-3 px-8 rounded-full w-fit my-10 mx-auto"
                                :
                                "bg-blue-400 hover:bg-blue-700 hover:text-white text-gray-800 font-bold py-3 px-8 rounded-full w-fit my-10 relative left-14"
                            }>
                            Apply For 2023 Intakes
                        </Link>
                    </div>
                    <img className={isMobile ? 'pt-12 w-max px-5' : 'pt-12 h-96 girlimg'} src="/images/home-girl.webp" alt="Logo" data-aos="fade-right"/>
                </div>
            </div>
            <h1 data-aos="fade-up" className=' font-custom text-4xl font-bold text-gray-800 mt-16 mb-14 mx-auto ml-5 mr-5'>With you at every step - from one point to another</h1>
            <div className='flex flex-wrap justify-center'>
                {data.map((item, index) => {
                    return (
                        <div data-aos="fade-up" key={index} className={(isMobile ? 'w-fit' : 'w-96') + ' flex flex-col  justify-start mx-10 mb-20 h-max'}>
                            <img className='mx-auto' src={item.Image} alt="Logo" />
                            <div className='flex flex-col justify-start text-start px-10 bg-green-300 rounded-xl'>
                                <h1 className='font-custom text-2xl text-gray-800 mt-8 font-semibold'>{item.heading}</h1>
                                <p className='font-custom text-gray-800 my-14 mb-16'>{item.text}</p>
                                <Link
                                    className=" bg-blue-400 hover:bg-blue-700 hover:text-white text-gray-800 font-bold py-3 rounded-full w-fit -mb-4 relative mx-auto px-12"
                                    to={item.url}>
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='flex flex-wrap justify-center'>
                {data2.map((item, index) => {
                    return (
                        <div data-aos="fade-up" key={index} className={(isMobile ? 'w-fit' : 'w-96') + ' flex flex-col  justify-start mx-10 mb-20 h-max'}>
                            <img className='mx-auto' src={item.Image} alt="Logo" />
                            <div className='flex flex-col justify-start text-start px-10 bg-green-300 rounded-xl'>
                                <h1 className='font-custom text-2xl text-gray-800 mt-8 font-semibold'>{item.heading}</h1>
                                <p className='font-custom text-gray-800 my-14 mb-16'>{item.text}</p>
                                <Link
                                    className=" bg-blue-400 hover:bg-blue-700 hover:text-white text-gray-800 font-bold py-3 rounded-full w-fit -mb-4 relative mx-auto px-12"
                                    to={item.url}>
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div>
                <div className={isMobile ?
                    'py-14 conatiner w-auto bg-indigo-800 flex flex-col justify-around '
                    :
                    'py-14 conatiner w-auto bg-indigo-800 flex flex-row justify-around '
                }>
                    <h1 data-aos="fade-right" className={isMobile ? "ml-5 text-start my-auto text-5xl font-bold text-white"
                        :
                        "my-auto text-7xl font-bold text-white  w-1/3"
                    }>Why GIC ?</h1>
                    <div className={isMobile ?
                        'mx-5 mt-10 flex flex-col'
                        :
                        'w-1/3 flex flex-col'
                    }>
                        <p data-aos="fade-right" className={isMobile ?
                            'w-fit text-start text-white text-lg'
                            :
                            'w-fit text-start text-white'
                        }>
                            Like you, a remarkable number of international students from across the world, from diverse backgrounds, have achieved their international education goals successfully through us. Our ethical, accurate & friendly guidance in the last 15 years has been the recipe. Now, it's your turn. Tell us your dreams, we will get you there.
                        </p>
                        <div className='flexcontainer  flex-row justify-around items-center mt-10'>
                            <div data-aos="fade-right" className='flex flex-col justify-center items-center'>
                                <img className='w-3/4' src="/images/why/1.webp" alt="Logo" />
                                <h1 className='w-3/4 font-bold text-center text-white pt-2 pb-5'>Located in 15<br />Countries</h1>
                            </div>
                            <div data-aos="fade-right" className='flex flex-col justify-center items-center'>
                                <img className=' w-3/4' src="/images/why/2.webp" alt="Logo" />
                                <h1 className='w-3/4 font-bold text-center text-white pt-2  pb-5'>End to End<br />Services</h1>
                            </div>
                            <div></div>
                            <div data-aos="fade-right" className='flex flex-col justify-center items-center'>
                                <img className='w-3/4' src="/images/why/3.webp" alt="Logo" />
                                <h1 className='w-3/4 font-bold text-center text-white pt-2  pb-5'>750+ Partner<br />Institutions</h1>
                            </div>
                            <div data-aos="fade-right" className='flex flex-col justify-center items-center'>
                                <img className='w-3/4' src="/images/why/4.webp" alt="Logo" />
                                <h1 className='w-3/4 font-bold text-center text-white pt-2  pb-5'>High Visa<br />Success Rate</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h1 data-aos="fade-down" className=' font-custom text-4xl font-bold text-gray-800 mt-16 mb-14 mx-auto ml-5 mr-5'>Hey, we’d like to know you better too!</h1>
            <div className={isMobile ? 'flex flex-col justify-center m-5' : 'flex flex-row justify-center m-10 mr-5'}>
                <img data-aos="fade-up" src='./images/contact.png' alt='contactus' className={isMobile ? 'w-fit h-fit mb-10 mx-auto' : 'w-1/3 mr-10 h-fit my-auto mt-10'} />
                <ContactUs/>
            </div>
            <FuturePortal />
            <Strip />
            <Footer />
        </div>

    );
}
// export default Navbar;

export default Home;
